import { useLazyQuery, useQuery } from 'react-apollo';

import {
  City,
  GetCitiesData,
  GetCitiesLiteData,
  GetCitiesLiteVariables,
  GetCitiesVariables,
  GetCityData,
  GetCityForClpData,
  GetCityVariables,
} from 'app/typings/cities';
import { SharedGetDataParameters } from 'app/typings/generics';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_CITIES,
  GET_CITIES_FOR_CITY_SELECTOR,
  GET_CITIES_FOR_GROUP_BY_BUSINESS_OWNER,
  GET_CITIES_FOR_WEBSITE_FOOTER,
  GET_CITIES_LITE,
  GET_CITY,
  GET_CITY_FOR_CLP,
  GET_CLOSEST_CITIES,
} from 'app/shared/graphql/cities/queries';

interface GetClosestCitiesVariables extends SharedGetDataParameters {
  count?: number;
  prioritizeOAndOCities?: boolean;
  skipCachedCities?: boolean;
}

interface GetClosestCitiesData {
  __typename?: string;
  closestCities: {
    cities: City[];
  };
}
const liteQueryParams = (params: any) =>
  Object.assign(params, { isLiteQuery: true });

export const GetCities = (params: GetCitiesVariables = {}) => {
  return useQuery<GetCitiesData>(GET_CITIES, queryParams(params));
};

export const GetCitiesForCitySelectorLazy = (
  params: GetCitiesLiteVariables = {}
) => {
  return useLazyQuery<GetCitiesLiteData>(
    GET_CITIES_FOR_CITY_SELECTOR,
    queryParams(liteQueryParams(params))
  );
};

export const GetCitiesForGroupByBusinessOwner = (
  params: GetCitiesLiteVariables = {}
) => {
  return useQuery<GetCitiesLiteData>(
    GET_CITIES_FOR_GROUP_BY_BUSINESS_OWNER,
    queryParams(liteQueryParams(params))
  );
};

export const GetCitiesForWebsiteFooter = (
  params: GetCitiesLiteVariables = {}
) => {
  return useQuery<GetCitiesLiteData>(
    GET_CITIES_FOR_WEBSITE_FOOTER,
    queryParams(liteQueryParams(params))
  );
};

export const GetCitiesLite = (params: GetCitiesLiteVariables = {}) => {
  return useQuery<GetCitiesLiteData>(
    GET_CITIES_LITE,
    queryParams(liteQueryParams(params))
  );
};

export const GetCity = (params: GetCityVariables = {}) => {
  return useQuery<GetCityData>(GET_CITY, queryParams(params));
};

export const GetCityForClp = (params: GetCityVariables = {}) => {
  return useQuery<GetCityForClpData>(GET_CITY_FOR_CLP, queryParams(params));
};

export const GetClosestCities = (params: GetClosestCitiesVariables = {}) => {
  return useQuery<GetClosestCitiesData>(
    GET_CLOSEST_CITIES,
    queryParams(params)
  );
};

export const GetClosestCitiesLazy = (
  params: GetClosestCitiesVariables = {}
) => {
  return useLazyQuery<GetClosestCitiesData>(
    GET_CLOSEST_CITIES,
    queryParams(params)
  );
};
